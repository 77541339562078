import React, { useState, useEffect, useMemo } from "react";
import { useTransition, animated, config } from "react-spring";
import useIsSsr from "../../hooks/useIsSsr";

type START_POSITION = "random" | "lowerLeft" | "lowerRight";
type Dimensions = { width: number; height: number };

const getStartPosition = (
  from: START_POSITION,
  windowDimensions: Dimensions
) => {
  if (from === "lowerLeft") {
    return { startX: -windowDimensions.width, startY: windowDimensions.height };
  } else if (from === "lowerRight") {
    return { startX: windowDimensions.width, startY: windowDimensions.height };
  } else {
    const r = Math.random();

    const angle = r * 2 * Math.PI;
    const distance = Math.sqrt(
      windowDimensions.width * windowDimensions.width +
        windowDimensions.height * windowDimensions.height
    );
    return {
      startX: (Math.cos(angle) * distance) / 2,
      startY: (Math.sin(angle) * distance) / 2
    };
  }
};

const FlyInText: React.FC<{
  children: string;
  from?: START_POSITION;
}> = ({ children, from = "random" }) => {
  const isSsr = useIsSsr();

  const [windowDimensions, setWindowDimensions] = useState<Dimensions>({
    width: 4000,
    height: 2000
  });

  useEffect(() => {
    if (isSsr) return;

    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, [isSsr]);

  const animatedElements = useMemo(
    () =>
      children.split("").map((_, i) => ({
        letter: _,
        i,
        ...getStartPosition(from, windowDimensions)
      })),
    [windowDimensions, from, children]
  );

  const transitions = useTransition(animatedElements, e => e.i, {
    from: item => ({
      transform: `translate3d(${item.startX}px, ${item.startY}px, 0)`
    }),
    enter: { transform: `translate3d(0, 0, 0)` },
    config: { mass: 5, tension: 500, friction: 80 },
    trail: 75
  });

  if (!windowDimensions) return null;

  return (
    <span>
      {transitions.map(({ item, props, key }) => (
        <animated.span
          key={key}
          style={{ ...props, display: "inline-block", whiteSpace: "pre" }}
        >
          {item.letter}
        </animated.span>
      ))}
    </span>
  );
};

export default FlyInText;
