import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import FlyInText from "../components/FlyInText";

const NameContainer = styled.div`
  padding: 20px;
  font-size: 36px;
`;

const Chris: React.FC = () => {
  return (
    <Layout>
      <NameContainer>
        <FlyInText>Chris Erickson</FlyInText>
      </NameContainer>
    </Layout>
  );
};

export default Chris;
